import { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { css } from '@emotion/core'

export interface ButtonProps {
  style?: keyof typeof BUTTON_STYLES;
  label: string;
  url?: string;
}
export const Button: FunctionComponent<ButtonProps> = props => {
  const StyledButton = props.style ? BUTTON_STYLES[props.style] : BUTTON_STYLES['primary-solid'];
  return (
    <StyledButton href={props.url}>
      {props.label}
    </StyledButton>
  )
}

const BaseBlockStyle = css`
  min-width: 100px;
  padding: 5px;
  border: 3px solid transparent;
`;
const BaseStyle = css`
  display: inline-block;
  /* height: 50px; */
  
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
`;

const PrimarySolidStyle = styled.a`
  ${BaseStyle};
  ${BaseBlockStyle};
  color: white;
  background-color: ${props => props.theme.colors.primary};

  

  &:hover {
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    background-color: white;
  }
`;

const PrimaryOutlineStyle = styled.a`
  ${BaseStyle};
  ${BaseBlockStyle};
  color: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};

  padding: 5px;

  &:hover {
    color: white;
    background-color: ${props => props.theme.colors.primary};
    border-color: transparent;
  }
`;

const PrimaryGhostStyle = styled.a`
  ${BaseStyle};
  ${BaseBlockStyle};
  color: ${props => props.theme.colors.primary};

  padding: 5px;

  &:hover {
    color: ${props => props.theme.colors.primary};
    background-color: ${props => {
      const primary = props.theme.colors.primary;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const [r, g, b] = primary.match(/\w\w/g)!.map(x => parseInt(x, 16));
      return `rgba(${r},${g},${b},0.05)`;
    }};
  }
`;

const PrimaryLinkStyle = styled.a`
  ${BaseStyle};
  color: ${props => props.theme.colors.primary};

  &:hover {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
  }
`;

const BlackLinkStyle = styled.a`
  ${BaseStyle};
  color: black;

  &:hover {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
  }
`;

export const BUTTON_STYLES = {
  'primary-solid': PrimarySolidStyle,
  'primary-outline': PrimaryOutlineStyle,
  'primary-ghost': PrimaryGhostStyle,
  'primary-link': PrimaryLinkStyle,
  'black-link': BlackLinkStyle,
}
