import styled from "@emotion/styled";

interface Props {
  // overflow?: boolean;
  max?: number;
}

export const MaxWidthContainer = styled.div<Props>`
  margin: 0 auto;
  padding: 0 8px;
  max-width: ${props => props.max}px;
  width: 100%;
`;
