import { MaxWidthContainer } from "../materials/MaxWidthContainer";
import styled from "@emotion/styled";
import { FunctionComponent } from "react";
import { Markdown } from "../../Markdown";
import { Block } from "..";
import { StaticContentEditor } from "../editors/StaticContentEditor";

const Wrapper = styled.div`
  padding-top: 36px;
`;

const Content = styled.div`
  /* overflow: hidden; */
  /* height: 100px; */
  /* font-size: 18px; */
  clear: both;
  /* line-height: 1.6em; */

  /* @media (max-width: 800px) {
    font-size: 14px;
  } */
`;

interface Props {
  content: string;
}
const Element: FunctionComponent<Props> = (props) => {
  const { content } = props;

  return (
    <Wrapper>
      <MaxWidthContainer max={800}>
        <Content>
          <Markdown body={content} />
        </Content>
      </MaxWidthContainer>
    </Wrapper>
  );
};

export const StaticContent0: Block<Props> = {
  element: Element,
  defaultProps: {
    content:
      "# Hello\n\nThis is **static** content.\n\nYou can edit this text by clicking edit button.",
  },
  editors: [
    {
      title: "Content",
      element: StaticContentEditor,
      propName: "content",
    },
  ],
};
