import { Editor } from ".."
import { Input } from "antd"

export const LabelEditor: Editor<string> = ({ blockProps, onEditBlock, propName }) => (
  <Input
    value={blockProps[propName]}
    onChange={e => {
      onEditBlock({
        ...blockProps,
        [propName]: e.target.value,
      })
    }}
  />
)