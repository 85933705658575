import { useState } from "react"
import { useApolloClient } from "@apollo/react-hooks";
import Upload, { RcFile, UploadChangeParam } from "antd/lib/upload";
import { message, Button, Slider } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import { Editor } from "..";

export const SingleImageEditor: Editor<any> = ({ blockProps, onEditBlock, propName }) => {

  const [uploading, setUploading] = useState(false);
  const client = useApolloClient();

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    }
    if (info.file.status === 'done') {
      const url = info.file.response.url.split('?')[0];
      setUploading(false);
      onEditBlock({
        ...blockProps,
        [propName]: {
          ...blockProps[propName],
          url,
        }
      })
    }
  }

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      <Upload
        listType="picture-card"
        showUploadList={false}
        method="PUT"
        action={async file => {
          const { data } = await client.query({
            query: gql`
              query PresignedUrl($input: PresignedUrlInput!) {
                presignedUrl(input: $input)
              }
            `,
            variables: {
              input: {
                type: file.type,
                name: file.name,
              }
            }
          })
          return data.presignedUrl;
        }}
        customRequest={({
          action,
          file,
          onError,
          onSuccess,
        }) => {
          fetch(action, {
            method: 'PUT',
            body: file,
            mode: 'cors',
          })
          .then(res => {
            onSuccess(res, file);
          })
          .catch(onError);
      
          return {
            abort() {
              // console.log('upload progress is aborted.');
            },
          };
        }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {blockProps[propName] ? <img src={blockProps[propName].url} alt="logo" style={{ width: '100%' }} /> : uploadButton}
      </Upload>

        {blockProps[propName] ? (
          <div>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                delete blockProps[propName];
                onEditBlock({ ...blockProps })
              }}
            >
              Remove
            </Button>
            <Slider
              min={10}
              max={100}
              tipFormatter={v => `${v}px`}
              value={blockProps[propName].size}
              onChange={value => {
                onEditBlock({
                  ...blockProps,
                  [propName]: {
                    ...blockProps[propName],
                    size: value,
                  }
                })
              }}
            />
          </div>
        ) : null}
    </div>
  )
}

