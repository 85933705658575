import Head from "next/head";
import styled from "@emotion/styled";
import { Logo } from "./Logo";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Noto Sans KR', sans-serif;
  height: 100%;
`;

export const Main = styled.main`
  flex: 1 0 auto;
`;

export const Branding = styled.div`
  flex-shrink: 0;

  font-size: 11px;
  padding: 5px 0;
  /* font-weight: bold; */

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: black;
  color: white;

  a {
    color: white;
    vertical-align: middle;
  }
`;

interface PageLayoutProps {
  title?: string;
  branding?: boolean;
}
export const PageLayout: React.FunctionComponent<PageLayoutProps> = props => {
  return (
    <Wrapper>
      <Head>
        <title>{props.title || "Avocado Stadium"}</title>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Head>

      <Main>
        {props.children}
      </Main>
      {props.branding ? (
        <Branding>
          <a href={process.env.NEXT_PUBLIC_APP_HOST}>
          Powered by <Logo size={14} style={{ marginLeft: 7, marginRight: 3 }}/> Avocado Stadium
          </a>
        </Branding>
      ) : null}
    </Wrapper>
  )
}