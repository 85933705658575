import { MaxWidthContainer } from "../materials/MaxWidthContainer";
import styled from "@emotion/styled";
import { FunctionComponent } from "react";
import Link from "next/link";
import { Markdown } from "../../Markdown";
import { Block } from "..";

const Wrapper = styled.div`
  padding-top: 36px;
`;

const PostWrapper = styled.div`
  padding-bottom: 54px;

  /* @media (max-width: 800px) {
    padding-bottom: 1em;
  } */

  &:first-child {
    margin-top: 0;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin: 0;

  a {
    color: #000;
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  /* @media (max-width: 992px) {
    font-size: 36px;
  } */
`;

const Info = styled.div`
  color: #797979;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: 500;
  font-size: 16px;
`;

const ContentTruncate = styled.div`
  overflow: hidden;
  height: 100px;
  /* font-size: 18px; */
  clear: both;
  /* line-height: 1.6em; */
  margin-top: 36px;

  /* @media (max-width: 800px) {
    font-size: 14px;
  } */
`;

const Content = styled.div`
  overflow: hidden;
  height: 100px;
`;

const ContentMask = styled.div`
  top: -100px;
  position: relative;
  height: 100px;
  display: block;
  background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 1));
`;

interface Props {
  data: {
    posts: any[];
  };
}
const Element: FunctionComponent<Props> = (props) => {
  return (
    <Wrapper>
      {props.data.posts.map((post, index) => (
        <MaxWidthContainer max={800} key={index}>
          <PostWrapper>
            <Title>
              <Link href={`/${post.slug}`}>
                <a>{post.title}</a>
              </Link>
            </Title>
            <Info>
              <time>
                {new Date(post.createdAt)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ")}
              </time>
            </Info>
            <ContentTruncate>
              <Content>
                <Markdown
                  body={post.body.split("\n\n").slice(0, 5).join("\n\n")}
                />
              </Content>
              <ContentMask />
            </ContentTruncate>
          </PostWrapper>
        </MaxWidthContainer>
      ))}
    </Wrapper>
  );
};

export const PostList0: Block<Props> = {
  element: Element,
  defaultProps: {
    data: {
      posts: [
        {
          title: "Lorem Ipsum",
          slug: "slug",
          createdAt: new Date().valueOf(),
          body:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Lorem Ipsum",
          slug: "slug",
          createdAt: new Date().valueOf(),
          body:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Lorem Ipsum",
          slug: "slug",
          createdAt: new Date().valueOf(),
          body:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Lorem Ipsum",
          slug: "slug",
          createdAt: new Date().valueOf(),
          body:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Lorem Ipsum",
          slug: "slug",
          createdAt: new Date().valueOf(),
          body:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
      ],
    },
  },
};
