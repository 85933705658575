import { Editor } from "..";
import { Input, Button, Card, Space, Select } from "antd";
import update from "immutability-helper";
import { useEffect } from "react";
import { BUTTON_STYLES, ButtonProps } from "../materials/Button";

export const ButtonsEditor: Editor<ButtonProps[]> = ({
  blockProps,
  onEditBlock,
  propName,
}) => {
  // FIX: https://github.com/shiftcode/dynamo-easy/issues/318
  useEffect(() => {
    if (!blockProps[propName]) {
      onEditBlock({
        ...blockProps,
        [propName]: [],
      });
    }
  }, []);

  const handleAdd = () => {
    onEditBlock({
      ...blockProps,
      [propName]: [...blockProps[propName], {}],
    });
  };

  const handleRemove = (index: number) => () => {
    blockProps[propName].splice(index, 1);
    onEditBlock({
      ...blockProps,
      [propName]: [...blockProps[propName]],
    });
  };

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      {blockProps[propName] &&
        blockProps[propName].map((button, index) => (
          <Card key={index}>
            <Space direction="vertical" style={{ display: "flex" }}>
              <Select<keyof typeof BUTTON_STYLES>
                value={button.style}
                defaultValue="primary-solid"
                onChange={(value) => {
                  onEditBlock({
                    ...blockProps,
                    [propName]: update(blockProps[propName], {
                      [index]: {
                        style: { $set: value },
                      },
                    }),
                  });
                }}
              >
                {Object.keys(BUTTON_STYLES).map((style) => (
                  <Select.Option key={style} value={style}>
                    {style}
                  </Select.Option>
                ))}
              </Select>
              <Input
                placeholder="About"
                value={button.label}
                onChange={(e) => {
                  const value = e.target.value;
                  onEditBlock({
                    ...blockProps,
                    [propName]: update(blockProps[propName], {
                      [index]: {
                        label: { $set: value },
                      },
                    }),
                  });
                }}
              />
              <Input
                placeholder="/about"
                value={button.url}
                onChange={(e) => {
                  const value = e.target.value;
                  onEditBlock({
                    ...blockProps,
                    [propName]: update(blockProps[propName], {
                      [index]: {
                        url: { $set: value },
                      },
                    }),
                  });
                }}
              />
              <Button type="link" size="small" onClick={handleRemove(index)}>
                Remove
              </Button>
            </Space>
          </Card>
        ))}
      <Button onClick={handleAdd}>Add</Button>
    </Space>
  );
};
