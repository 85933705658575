import { Navigation0 } from './elements/Navigation0';
import { PostList0 } from './elements/PostList0';
import { Navigation1 } from './elements/Navigation1';
import { Post0 } from './elements/Post0';
import { StaticContent0 } from './elements/StaticContent0';
import { Profile0 } from './elements/Profile0';

import { FunctionComponent } from "react";

export type Editor<T> = FunctionComponent<{
  blockProps: { [propName: string]: T };
  onEditBlock: (props: any) => void;
  propName: string;
}>

export interface Block<T> {
  element: FunctionComponent<T>;
  defaultProps?: T;
  editors?: {
    title: string;
    element: FunctionComponent<any>;
    propName?: string;
  }[];
}

interface BlockCategory {
  name: string;
  description: string;
  elements: Block<any>[];
}

export const blocksByCategoryName = new Map<string, BlockCategory>([
  ['Navigation', {
    name: 'Navigation',
    description: '',
    elements: [
      Navigation0,
      Navigation1,
    ],
  }],
  
  ['Profile', {
    name: 'profile',
    description: '',
    elements: [
      Profile0,
    ],
  }],

  ['PostList', {
    name: 'Post list',
    description: '',
    elements: [
      PostList0,
    ],
  }],

  ['Post', {
    name: 'Post',
    description: '',
    elements: [
      Post0,
    ],
  }],

  ['StaticContent', {
    name: 'Static content',
    description: '',
    elements: [
      StaticContent0,
    ],
  }],
]);

export const getBlockByName = (blockName: string): Block<any>|undefined => {
  const [categoryName, index] = blockName.split('-');
  const category = blocksByCategoryName.get(categoryName);
  return category?.elements[Number(index)];
}
