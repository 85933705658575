import styled from "@emotion/styled";
import { FunctionComponent } from "react";
import { MaxWidthContainer } from "../materials/MaxWidthContainer";
import { Block } from "..";
import { SingleImageEditor } from "../editors/SingleImageEditor";
import { LabelEditor } from "../editors/LabelEditor";

interface LogoProps {
  url: string;
  size: number;
}

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 2em 5px;
  margin: 0 auto;
  font-size: 16px;
`;

const Logo = styled.img`
  margin: 16px;
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.primary};
`;

const Title = styled.div`
  /* display: block; */
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Description = styled.div`
  text-align: center;
  color: gray;
  width: 320px;
`;

interface Props {
  logo?: LogoProps;
  subtitle?: string;
  title?: string;
  description?: string;
}
const Element: FunctionComponent<Props> = (props) => {
  return (
    <MaxWidthContainer max={800}>
      <Wrapper>
        {props.logo && <Logo src={props.logo.url} height={props.logo.size} />}
        {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
        {props.title && <Title>{props.title}</Title>}
        {props.description && <Description>{props.description}</Description>}
      </Wrapper>
    </MaxWidthContainer>
  );
};

export const Profile0: Block<Props> = {
  element: Element,
  defaultProps: {
    logo: {
      url:
        "https://avocadostadium.com/logo.png",
      size: 50,
    },
    subtitle: "SUBTITLE",
    title: "Title",
    description: "This is description",
  },
  editors: [
    {
      title: "Logo",
      element: SingleImageEditor,
      propName: "logo",
    },
    {
      title: "Subtitle",
      element: LabelEditor,
      propName: "subtitle",
    },
    {
      title: "Title",
      element: LabelEditor,
      propName: "title",
    },
    {
      title: "Description",
      element: LabelEditor,
      propName: "description",
    },
  ],
};
