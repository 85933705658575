import { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { MaxWidthContainer } from "../materials/MaxWidthContainer";
import { Block } from "..";
import { ButtonsEditor } from "../editors/ButtonsEditor";
import { Button, ButtonProps } from "../materials/Button";

const Border = styled.div`
  height: 4px;
  background-color: ${(props) => props.theme.colors.primary};
`;

const Wrapper = styled.footer`
  padding: 16px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $max-width;
  overflow: hidden;
  margin: 0 auto;
  font-size: 16px;

  /* @media (max-width: 800px) {
    font-size: 15px;
  } */
`;

interface Props {
  buttons: ButtonProps[];
}
const Element: FunctionComponent<Props> = (props) => (
  <div>
    <Border />
    <MaxWidthContainer max={800}>
      <Wrapper>
        {props.buttons &&
          props.buttons.map((button, index) => (
            <Button key={index} {...button} />
          ))}
      </Wrapper>
    </MaxWidthContainer>
  </div>
);

export const Navigation1: Block<Props> = {
  element: Element,
  defaultProps: {
    buttons: [],
  },
  editors: [
    {
      title: "Buttons",
      element: ButtonsEditor,
      propName: "buttons",
    },
  ],
};
