import { Editor } from '..';
import {Controlled as CodeMirror} from 'react-codemirror2'
import styled from '@emotion/styled';

try {
  require('codemirror/mode/markdown/markdown');
  require("codemirror/addon/display/placeholder");
// eslint-disable-next-line no-empty
} catch (e) {}

const Wrapper = styled.div`
  border: 1px solid gray;
`;

export const StaticContentEditor: Editor<string> = ({ blockProps, onEditBlock, propName }) => {
  return (
    <Wrapper>
      <CodeMirror
        value={blockProps[propName]}
        options={{
          mode: 'markdown',
          lineWrapping: true,
        }}
        onBeforeChange={(_editor, _data, value) => {
          onEditBlock({
            ...blockProps,
            [propName]: value,
          });
        }}
      />
    </Wrapper>
  )
}