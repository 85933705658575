import Link from "next/link";
import styled from "@emotion/styled";
import { MaxWidthContainer } from "../materials/MaxWidthContainer";
import { FunctionComponent } from "react";
import { Block } from "..";
import { SingleImageEditor } from "../editors/SingleImageEditor";
import { LabelEditor } from "../editors/LabelEditor";
import { ButtonsEditor } from "../editors/ButtonsEditor";
import { Button, ButtonProps } from "../materials/Button";

const Wrapper = styled.div`
  background-color: white;
  font-size: 16px;
`;

interface LogoProps {
  url: string;
  size: number;
}
const Logo: React.FunctionComponent<LogoProps> = (props) => {
  return props.url ? (
    <Link href="/">
      <a style={{ marginRight: 8 }}>
        <img src={props.url} height={props.size} />
      </a>
    </Link>
  ) : (
    <div />
  );
};

const Border = styled.div`
  height: 4px;
  background-color: ${(props) => props.theme.colors.primary};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 16px 0;
  margin: 0 auto;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;

  a {
    color: black;
    text-decoration: none;
    display: inline-flex;
    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  /* @media (max-width: 800px) {
    font-size: 16px;
  } */
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    margin: 0 16px;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

interface Props {
  logo?: LogoProps;
  title?: string;
  buttons?: ButtonProps[];
}
const Element: FunctionComponent<Props> = (props) => {
  return (
    <Wrapper>
      <MaxWidthContainer max={800}>
        <Header>
          <Title>
            {props.logo && <Logo url={props.logo.url} size={props.logo.size} />}
            {props.title && (
              <Link href="/">
                <a>{props.title}</a>
              </Link>
            )}
          </Title>
          <Menu>
            {props.buttons &&
              props.buttons.map((button, index) => (
                <Button key={index} {...button} />
              ))}
          </Menu>
        </Header>
      </MaxWidthContainer>

      <Border />
    </Wrapper>
  );
};

export const Navigation0: Block<Props> = {
  element: Element,
  defaultProps: {
    logo: {
      url: "https://avocadostadium.com/logo.png",
      size: 50,
    },
    title: "Title",
    buttons: [],
  },
  editors: [
    {
      title: "Logo",
      element: SingleImageEditor,
      propName: "logo",
    },
    {
      title: "Title",
      element: LabelEditor,
      propName: "title",
    },
    {
      title: "Buttons",
      element: ButtonsEditor,
      propName: "buttons",
    },
  ],
};
